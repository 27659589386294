import React, { Component } from 'react';
import SwyxDropDownList from './swyx-drop-down-list';
import SwyxTextBox from './swyx-text-box';
import SwyxCheckBox from './swyx-check-box';

class InputElement extends Component {
    onValueChanged = newValue => {
        this.props.onValueChanged(this.props.viewModel.id, newValue);
    }

    BuildSwyxDropDownList = ({
        enumValues,
        defaultValue,
        isReadonly,
        value,
        id,
        descriptionEN, 
        descriptionDE }) => {
        return (<SwyxDropDownList
            enumValues={enumValues}
            defaultValue={defaultValue}
            isReadonly={isReadonly}
            value={value}
            onValueChanged={this.onValueChanged}
            id={id}
            localize={this.props.localize}
            descriptionEN={descriptionEN}
            descriptionDE={descriptionDE} />)
    };

    BuildSwyxTextBox = ({
        range,
        maxLength,
        defaultValue,
        required,
        isReadonly,
        value,
        id,
        descriptionEN, 
        descriptionDE
    }, dataType) => {
        return (<SwyxTextBox
            range={range}
            dataType={dataType}
            maxLength={maxLength}
            required={required}
            defaultValue={defaultValue}
            isReadonly={isReadonly}
            value={value}
            onValueChanged={this.onValueChanged}
            id={id}
            localize={this.props.localize}
            descriptionEN={descriptionEN}
            descriptionDE={descriptionDE} />)
    };

    BuildSwyxCheckBox = ({
        defaultValue,
        isReadonly,
        value,
        id,
        descriptionEN, 
        descriptionDE }) => {
        return (<SwyxCheckBox
            defaultValue={defaultValue}
            isReadonly={isReadonly}
            value={value}
            onValueChanged={this.onValueChanged}
            id={id}
            localize={this.props.localize}
            descriptionEN={descriptionEN}
            descriptionDE={descriptionDE} />)
    };

    BuildInputControl = () => {
        const vm = this.props.viewModel;
        const { dataType, enumValues } = vm;

        if (enumValues) {
            return this.BuildSwyxDropDownList(vm);
        }

        switch (dataType) {
            case "integer":
                return this.BuildSwyxTextBox(vm, 'integer');
            case "string":
                return this.BuildSwyxTextBox(vm);
            case "password":
                return this.BuildSwyxTextBox(vm, 'password');
            case "boolean":
                return this.BuildSwyxCheckBox(vm);
            case "ip":
                return this.BuildSwyxTextBox(vm, 'ip');
            case "fqdn":
                return this.BuildSwyxTextBox(vm, 'fqdn');
            default:
                return (<div>N/A</div>);
        }
    };

    render() {
        const vm = this.props.viewModel;
        return ( vm.isVisible &&
            <div>                
                {this.BuildInputControl(vm)}
            </div>
        );
    }
}

export default InputElement;