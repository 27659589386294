import React,  { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import germanFlag from '../assets/de.png';
import ukFlag from '../assets/en-gb.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import RESOURCES from '../scripts/resources';

const OPTIONS = [
    { 
        'id': 'en',
        'value': <span className="language-menu"><span>{RESOURCES.languages.english}</span><img src={ukFlag} alt="en-gb" /></span>,
        'selected': <span className='selected'><FontAwesomeIcon icon={faGlobe} size="4x" /><span>{RESOURCES.languages.english2letters}</span></span>
    },
    { 
        'id': 'de',
        'value': <span className="language-menu"><span>{RESOURCES.languages.german}</span><img src={germanFlag} alt="de" /></span>,
        'selected': <span className='selected'><FontAwesomeIcon icon={faGlobe} size="4x" /><span>{RESOURCES.languages.german2letters}</span></span>
    }
];

class LanguageMenu extends Component {

  constructor(props) {
    super(props);
    this.state = { anchorEl: null, selectedIndex: 0 };
  }

  handleClickListItem = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (event, index) => {
    this.setState({ selectedIndex: index, anchorEl: null });    
    this.props.languageChanged(OPTIONS[index].id);    
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { selectedIndex, anchorEl } = this.state;
    return (
      <div>
      <List component="nav" aria-label="Language menu">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="language-menu"
          aria-label="selected language"
          onClick={this.handleClickListItem}
        >
          <ListItemText secondary={OPTIONS[selectedIndex].selected} />
        </ListItem>
      </List>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={this.handleClose}
      >
        {OPTIONS.map((option, index) => (
          <MenuItem
            key={option.id}
            selected={index === selectedIndex}
            onClick={event => this.handleMenuItemClick(event, index)}
          >
            {option.value}
          </MenuItem>
        ))}
      </Menu>
    </div>
    )    
  }
}

export default LanguageMenu;