import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography } from '@material-ui/core';

class SwyxRestartWizard extends Component {

  onRestart = () => {
    this.props.handleDialog(true);
  };

  onClose = () => {
    this.props.handleDialog(false);
  }

  render() {
    const { localize } = this.props;
    return (
      <div>
        <Dialog aria-labelledby="restart-wizard-dialog-title" open={this.props.restartWizard}>
          <DialogTitle id="restart-wizard-dialog-title">
            {localize.restartWizardHeader()}
          </DialogTitle>
          <DialogContent>
            <Typography>
            {localize.restartWizardMessage()}
            </Typography>            
          </DialogContent>
          <DialogActions>
            <div>
              <Button variant="outlined"
                onClick={this.onClose}
                className="float-right left-margin-32">{localize.noButtonCaption()}
                </Button>
              <Button variant="outlined"
                onClick={this.onRestart}
                className="float-right">{localize.yesButtonCaption()}
              </Button>
            </div>            
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default SwyxRestartWizard;