import React, { Component } from 'react';
import { FormControl, InputLabel, NativeSelect } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    root: {
        background: '#fff',
        display: 'inline-block',
        paddingLeft: 5,
        border: '1px solid',
        borderRadius: 0,
        borderColor: '#e6e6e6',
    },
    icon: {
        fill: 'white'
    }
});

class SwyxDropDownList extends Component {
    constructor(props) {
        super(props);
        const { value, defaultValue } = this.props;
        this.state = { value: value !== undefined ? value : defaultValue };
    };

    onChange = (e, onValueChanged) => {
        // validate
        const newValue = e.target.value;
        this.setState({ value: newValue });
        onValueChanged(newValue);
    };

    render() {
        const {
            enumValues,
            isReadonly,
            onValueChanged,
            id,
            classes,
            localize } = this.props;
            
        return (
            <FormControl fullWidth={true} disabled={isReadonly}>
                <InputLabel shrink={true} className="label-container" htmlFor={id}>{localize.dynamicDescription(this.props)}</InputLabel>
                <NativeSelect
                    disableUnderline={true}
                    variant="outlined"                    
                    inputProps={{
                        id: id.toString(),
                        value: this.state.value,
                        onChange: e => this.onChange(e, onValueChanged),
                        classes: {
                            root: classes.root,
                            icon: classes.icon
                        }
                    }}
                >
                    {enumValues.map(option => {
                        return (
                            <option
                                key={option.value}
                                value={option.value}>
                                {localize.dynamicOption(option)}
                            </option>
                        )
                    })}
                </NativeSelect>
            </FormControl>            
        )
    }
}

export default withStyles(styles)(SwyxDropDownList);
