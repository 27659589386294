class DependencyCalculator {
    static getComponentsValues(viewModel) {
        const componentsValues = [];
        for (let page of viewModel) {
            for (let component of page.components) {
                componentsValues[component.id] = component.value !== undefined ? component.value : component.defaultValue;
            }
        }

        return componentsValues;
    };

    static isDependencySatisfied = (condition, componentsValues) => {
        const isSimpleDependency = condition.refId !== undefined;
        if (isSimpleDependency) {
            return this.isSimpleConditionSatisfied(condition, componentsValues)
        } else {
            return this.isComplexConditionSatisfied(condition, componentsValues);
        }
    };

    static isSimpleConditionSatisfied = ({ refId, value, operator }, componentsValues) => {
        let dependencyValue = componentsValues[refId];

        if (typeof value === "number"){
            dependencyValue = parseInt(dependencyValue, 10);
        }
        
        switch (operator) {
            case "==":
                return value === dependencyValue;
            case "!=":
                return value !== dependencyValue;
            case ">":
                return value > dependencyValue;
            case "<":
                return value < dependencyValue;
            case ">=":
                return value >= dependencyValue;
            case "<=":
                return value <= dependencyValue;
            default:
                throw new Error("Wrong simple condition operator");
        }
    };

    static isComplexConditionSatisfied = (complexCondition, componentsValues) => {
        if (complexCondition.and !== undefined) {
            for (let simpleCondition of complexCondition.and) {
                let singleResult = this.isSimpleConditionSatisfied(simpleCondition, componentsValues);
                if (!singleResult) {
                    return false;
                }
            }

            return true;
        } else if (complexCondition.or !== undefined) {
            for (let simpleCondition of complexCondition.or) {
                let singleResult = this.isSimpleConditionSatisfied(simpleCondition, componentsValues);
                if (singleResult) {
                    return true;
                }
            }

            return false;
        }

        throw new Error("Wrong complex condition operator");
    };
}

export default DependencyCalculator;