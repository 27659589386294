class HandleError {
    constructor(localize) {
        this.localize = localize;
    }
    
    getHttpStatus = errorCode => {
        switch (errorCode) {
            case 400:
                return this.localize.invalidParameterMessage();
            case 404:
                return this.localize.missingFileMessage();
            case 530:
                return this.localize.missingKeyMessage();
            case 531:
                return this.localize.duplicatePlaceholdersMessage();
            case 532:
                return this.localize.placeholdersMismatchMessage();
            case 533:
                return this.localize.invalidDefaultValueMessage();
            case 534:
                return this.localize.duplicateIdsMessage();
            case 540:
                return this.localize.invalidOperatorMessage();
            case 541:
                return this.localize.invalidIfConditionMessage();
            case 542:
                return this.localize.missingEndIfMessage();
            case 543:
                return this.localize.invalidNumberMessage();
            case 544:
                return this.localize.invalidPlaceholderMessage();

            default:
                // for 500 code
                return this.localize.genericErrorMessage();
        }
    }

    handleError(errorCode) {
        const errorMessage = this.getHttpStatus(errorCode);
        return errorMessage;
    }
}

export default HandleError;