import React, { Component } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class SwyxErrorAlert extends Component {
  state = { open: false, message: ''};

  handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      
      this.props.resetMessage();
    };

  render() {
      return (
          <Snackbar
            open={this.props.message !== ''}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
          <Alert onClose={this.handleClose} severity="error">
            { this.props.message }!
          </Alert>
        </Snackbar>
      )
    }
}

export default SwyxErrorAlert;