import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { Grid, Button } from '@material-ui/core';
import isElectron from 'is-electron';

class ResultPage extends Component {
  // Possible new implementations (download & copy) needed for ElectronJS
  downloadFile = () => {
    const element = document.createElement("a");
    const file = new Blob([document.getElementById('resultedCli').value], { type: 'text/plain', endings: 'native' });
    element.href = URL.createObjectURL(file);
    element.download = "resultedCli.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  copyToClipboard = (e) => {
    document.getElementById('resultedCli').select();
    document.execCommand('copy');
    e.target.focus();
  };

  render() {
    const { localize, resultedCli } = this.props;
    
    return (
      <div>
        <Grid container>
          <Grid item xs={12} >
            <TextField
              id='resultedCli'
              multiline
              rows={isElectron() ? 15 : 25}
              fullWidth
              defaultValue={resultedCli}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <Button variant="outlined"
              onClick={this.downloadFile}>{localize.downloadButtonCaption()}
              </Button>
            {
              document.queryCommandSupported('copy') &&
              <Button variant="outlined"
                onClick={this.copyToClipboard}
                className="left-margin-32">{localize.copyToClipboardButtonCaption()}
                  </Button>
            }
          </Grid>
          <Grid item xs={12} sm={6} >
            <Button variant="outlined"
              onClick={this.props.restartWizard}
              className="float-right left-margin-32">{localize.restartButtonCaption()}
              </Button>
            <Button variant="outlined"
              onClick={this.props.goBack}
              className="float-right">{localize.backButtonCaption()}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ResultPage;