class Validation {
    
    isNullOrUndefinedOrEmpty = (value) => value == null || typeof value === 'undefined' || value.toString().trim() === '';
    
    isRequired = value => {
        return this.isNullOrUndefined(value) || value.toString().trim() === ''
    }

    required = component => {
        const { value, localize } = component;
        if (this.isNullOrUndefinedOrEmpty(value)) {
            return localize.requiredMessage(component);
        }

        return '';
    }

    fqdn = component => {
        const { value, localize } = component;
        // eslint-disable-next-line
        const fqdnRegex = /^(([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,})$/;
        if (!fqdnRegex.test(value)) {
            return localize.fqdnMessage(component);  
        }

        return '';
    }

    ip = component => {
        const { value, localize } = component;
        const ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
        if (!ipRegex.test(value)) {
            return localize.ipMessage(component);
        }

        return '';
    }

    isInRange = (number, range) => {
        return !range || (range.min <= number && number <= range.max);
    }

    integer = component => {
        const { value, range, localize } = component;
        const number = +value;
        if (typeof number === 'number' && Number.isInteger(number) && this.isInRange(number, range)) {
            return '';
        }

        return localize.integerMessage(component);
    }

    maxLength = component => {
        const { value, max, localize } = component;
        if (value.length > max) {
            return localize.maxLengthMessage(component);
        }
        
        return '';
    }

    validateByType = component => {
        switch (component.dataType) {
            case 'ip':
                return this.ip(component);
            case 'fqdn':
                return this.fqdn(component);
            case 'integer':
                return this.integer(component);
            default:
                return '';           
        }
    }

    validateComponent = component => {
        const requiredMessage = this.required(component);
        if (component.required && Boolean(requiredMessage)) {
            return requiredMessage;
        }

        if (!Boolean(requiredMessage)) {
            return this.validateByType(component);
        }
        
        return '';
    }

    shouldValidateComponent = component => {
        const { isVisible, dataType } = component;
        return isVisible && (dataType === 'ip' || dataType === 'string' || dataType === 'fqdn' || dataType === 'password' || dataType === 'integer') ;
    }

    componentsAreValid = viewModel => {
        const { components, localize } = viewModel;
        for (let component of components) {
            const shouldValidate = this.shouldValidateComponent(component);
            component['localize'] = localize;
            if (shouldValidate && Boolean(this.validateComponent(component))) {
                return false;
            }
        }

        return true;
    }
}

export default Validation;