import React, { Component } from 'react';
import {
    Switch,
    Route,
    Redirect,
    Link,
    withRouter
} from 'react-router-dom';
import isElectron from 'is-electron';
import DynamicPage from './pages/dynamic-page';
import TemplateSelectionPage from './pages/template-selection-page';
import ResultPage from './pages/result-page';
import ModelParser from '../scripts/model-parser'
import HostedTemplatesApi from '../infrastructure/hosted-templates-api';
import StandaloneTemplatesApi from '../infrastructure/standalone-templates-api';
import SwyxErrorAlert from './swyx-error-alert';
import { Tabs, Tab } from '@material-ui/core';
import SwyxRestartWizard from './swyx-restart-wizard';

class DynamicWizard extends Component {
    getInitialAppState = () => {
        const initialState = {
            viewModel: [],
            templateName: '',
            routes: [],
            currentWizardStep: 0,
            resultedCli: null,
            errorMessage: '',
            restartWizard: false
        };

        return initialState;
    };

    state = this.getInitialAppState();

    componentDidMount() {
        const { history } = this.props;
        history.listen((newLocation, action) => {
            if (action === "PUSH" && (newLocation.pathname !== this.currentPathname || newLocation.search !== this.currentSearch)) {
                this.currentPathname = newLocation.pathname;
                this.currentSearch = newLocation.search;

                history.push({
                    pathname: newLocation.pathname,
                    search: newLocation.search
                });
            } else {
                history.go(1);
                if (this.state.currentWizardStep !== 0) {
                    this.openRestartWizardDialog();
                }
            }
        });
    };

    getTemplatesApi = () =>
        isElectron() ? new StandaloneTemplatesApi(window.api, this.props.localize) : new HostedTemplatesApi(this.props.localize);

    onTemplateLoad = (props, templateName, model) => {
        const { viewModel, routes } = ModelParser.initializeViewModel(model.pages);
        this.setState({ viewModel, routes, templateName, errorMessage: '' }, () => this.goNext(props));
    };

    onFieldValueChanged = (id, value) => {
        const { viewModel, routes } = ModelParser.updateViewModelState(this.state.viewModel, id, value);
        this.setState({ viewModel, routes });
    };

    goBack = (props) => {
        let { currentWizardStep } = this.state;
        if (currentWizardStep === 1) {
            this.openRestartWizardDialog();
        } else {
            this.changeRoute(props.history, --currentWizardStep);
        }      
    };

    goNext = (props) => {
        let { currentWizardStep, routes } = this.state;
        if (currentWizardStep === routes.length - 2) {
            this.submitWizard(() => {
                this.changeRoute(props.history, ++currentWizardStep);
            });
        } else {
            this.changeRoute(props.history, ++currentWizardStep);
        }
    };

    changeRoute = (history, newWizardStep) => {
        history.push(this.state.routes[newWizardStep]);
        this.setState({ currentWizardStep: newWizardStep, errorMessage: '', restartWizard: false });
    };

    submitWizard = navigateToResults => {
        const { viewModel, templateName } = this.state;
        var templatesApi = this.getTemplatesApi();
        var placeholderValues = ModelParser.extractPlaceholderValues(viewModel);

        templatesApi.generateCli(templateName, placeholderValues)
            .then(cli => {
                this.setState({ resultedCli: cli });
                navigateToResults();
            })
            .catch(error => {
                this.setState({ errorMessage: error });
            });
    };

    openRestartWizardDialog = () => {
        this.setState({ restartWizard: true });
    };

    resetErrorMessage = () => this.setState({ errorMessage: '' });

    handleRestartWizardDialog = (restart) => {
        if (restart) {
            this.setState(this.getInitialAppState(), () => {
                this.changeRoute(this.props.history, 0);
            });
        } else {
            this.setState({ restartWizard: false });
        }      
    }

    tabChanged = (event, newValue) => {
        this.changeRoute(this.props.history, newValue);
    }

    render() {
        const vm = this.state.viewModel;
        const { localize } = this.props;

        return (
            <div>
                <div>
                    <SwyxErrorAlert message={this.state.errorMessage} resetMessage={this.resetErrorMessage} />
                </div>
                <div>
                    <SwyxRestartWizard handleDialog={this.handleRestartWizardDialog} restartWizard = {this.state.restartWizard} localize={localize} />
                </div>
                {
                    !vm.length &&
                    <Redirect to='/' />
                }
                {this.HeaderLinks(vm)}
                {this.NavigationConfig(vm)}
            </div>
        );
    };
    HeaderLinks = vm => {
        const { localize } = this.props;
        return (
            <Tabs variant="scrollable" onChange={this.tabChanged} value={this.state.currentWizardStep}>
                <Tab label={localize.templateSelectionHeader()} component={Link} to={'/'} disabled={true} />
                {
                    vm && vm.map(page => {
                        page['localize'] = localize;
                        return (page.isVisible &&
                            <Tab key={page.number} label={localize.dynamicDescription(page)} component={Link} to={'/wizardStep/' + page.number} 
                                disabled={true} />
                        )
                    })
                }
                {
                    this.state.currentWizardStep === this.state.routes.length - 1 &&
                    <Tab label={localize.resultHeader()} component={Link} to={'/result'} disabled={true} />
                }
            </Tabs>
        );
    };

    NavigationConfig = vm => {
        const { localize } = this.props;
        return (
            <Switch>
                <Route
                    exact
                    path="/"
                    render={
                        (props) => <TemplateSelectionPage
                            {...props}
                            localize = {localize}
                            templatesApi={this.getTemplatesApi()}
                            onTemplateLoad={(templateName, model) => this.onTemplateLoad(props, templateName, model)} />}
                />
                {
                    vm && vm.map(page => {
                        return (page.isVisible &&
                            <Route
                                key={page.number}
                                path={'/wizardStep/' + page.number}
                                render={
                                    (props) => <DynamicPage
                                        {...props}
                                        localize = {localize}
                                        viewModel={page}
                                        onFieldValueChanged={this.onFieldValueChanged}
                                        goBack={() => this.goBack(props)}
                                        goNext={() => this.goNext(props)}
                                        goNextLabel={this.state.currentWizardStep === this.state.routes.length - 2 ? 
                                            localize.submitButtonCaption() : localize.nextButtonCaption()}
                                    />
                                } />
                        )
                    })
                }
                {
                    this.state.currentWizardStep === this.state.routes.length - 1 &&
                    <Route
                        path="/result"
                        render={
                            (props) => <ResultPage
                                {...props}
                                localize = {localize}
                                resultedCli={this.state.resultedCli}
                                goBack={() => this.goBack(props)}
                                restartWizard={() => this.openRestartWizardDialog()}
                            />
                        }
                    />
                }
            </Switch>
        );
    };

};

export default withRouter(DynamicWizard);