import React, { Component } from 'react';
import InputElement from '../inputs/input-element';
import { Grid, Button } from '@material-ui/core';
import SwyxErrorAlert from '../swyx-error-alert';

class TemplateSelectionPage extends Component {
  state = {
    templates: [],
    selectedTemplate: '',
    errorMessage: ''
  };

  componentDidMount() {
    const templatesApi = this.props.templatesApi;
    templatesApi.getTemplates()
      .then(templates => {
        this.setState({ templates });
        if (templates.length > 0 && this.state.selectedTemplate === '') {
          this.setState({ selectedTemplate: templates[0] });
        }
      })
      .catch(error => this.setState({ errorMessage: error.message }));
  }

  loadTemplate = () => {
    const { templatesApi, onTemplateLoad } = this.props;
    const { selectedTemplate } = this.state;

    templatesApi.getTemplate(selectedTemplate)
      .then(json => onTemplateLoad(selectedTemplate, json))
      .catch(error => this.setState({ errorMessage: error.message }));
  }

  onFieldValueChanged = (id, value) => {
    this.setState({ selectedTemplate: value });
  }

  resetErrorMessage = () => this.setState({ errorMessage: '' });

  render() {
    const { localize } = this.props;
    return (
      <div>
        <div>
          <SwyxErrorAlert message={this.state.errorMessage} resetMessage={this.resetErrorMessage} />
        </div>
        <Grid container>
          <Grid item xs={12} sm={6} >
            <InputElement key="templateSelection"
              localize={localize}
              viewModel={
                {
                  id: "templateSelection",
                  descriptionEN: localize.templateSelectionCaption().en,
                  descriptionDE: localize.templateSelectionCaption().de,
                  dataType: "string",
                  defaultValue: "",
                  isVisible: true,
                  isReadonly: false,
                  enumValues: this.state.templates ? this.state.templates.map(template => {
                    return {
                      labelEN: template,
                      labelDE: template,
                      value: template
                    }
                  }) : []
                }
              }
              onValueChanged={this.onFieldValueChanged} />
          </Grid>
          <Grid item xs={12} sm={6} >
            <div className="top-margin-15">
              <Button variant="outlined" onClick={this.loadTemplate} className="button">{localize.templateSelectionButtonCaption()}</Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default TemplateSelectionPage;