import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../assets/swyx_logo_bar_180.png';
import LanguageMenu from './language-menu';

class SwyxHeader extends Component {

    languageChanged = language => {
        this.props.languageChanged(language);
    }
    
    render() {
        return (
            <div>
                <AppBar position="static" className="header">
                    <Toolbar>
                        <div className="header-item">
                            <p>
                                <img src={logo} alt="Swyx logo" />
                            </p>                        
                        </div>
                        <LanguageMenu languageChanged={this.languageChanged} />
                    </Toolbar>
                </AppBar>
            </div>
        )
    }
}

export default SwyxHeader;