import ErrorHandler from './error-handling';

class HostedTemplatesApi {
    constructor(localize) {
        this.localize = localize;
        //window.HOSTED_API_URL = "http://localhost:8080"; //uncomment this line for dev testing without docker
    }

    handleResponse = (response, resolve, reject) => {
        if (response.ok) {
            const responseJson = response.json();
            resolve(responseJson);
        } else {
            const errorHandler = new ErrorHandler(this.localize);
            const error = errorHandler.handleError(response.status);
            reject(error);
        }
    }

    getTemplates = () => {
        return new Promise((resolve, reject) => {
            fetch(`${window.HOSTED_API_URL}/templates`)
                .then(response => this.handleResponse(response, resolve, reject))
                .catch(err => reject(err));
        });
    }

    getTemplate = template => {
        return new Promise((resolve, reject) => {
            fetch(`${window.HOSTED_API_URL}/templates/${template}`)
                .then(response => this.handleResponse(response, resolve, reject))
                .catch(err => reject(err));
        });
    }

    generateCli = (templateName, placeholderValues) => {
        return new Promise((resolve, reject) => {
            fetch(`${window.HOSTED_API_URL}/configfile`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ templateName, placeholderValues })
            })
                .then(response => this.handleResponse(response, resolve, reject))
                .catch(err => reject(err));
        })
    }
}

export default HostedTemplatesApi;