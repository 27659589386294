import React, { Component } from 'react';
import { Input, FormControl, InputLabel } from '@material-ui/core';
import Validation from '../../infrastructure/validation';
import SwyxInvalidTooltip from '../swyx-invalid-tooltip';

class SwyxTextBox extends Component {

    constructor(props) {
        super(props);
        const { value, defaultValue } = this.props;
        this.state = { value: value !== undefined ? value : defaultValue, errorMessage: '' };       
    };

    hasErrors = () => {
        return Boolean(this.state.errorMessage);
    }

    onChange = (value, onValueChanged) => {
        // validate
        this.setState({ value });
        onValueChanged(value);
    };

    validate = () => {
        const validation = new Validation();
        this.setState( { errorMessage: validation.validateComponent(this.props) });        
    }

    render() {
        const {
            id,
            dataType,
            maxLength,
            required,
            isReadonly,
            onValueChanged,
            range,
            localize } = this.props;
        const inputType = dataType === 'password' ? "password" : "text";
        const { errorMessage } = this.state;
        const min = dataType === 'integer' && range ? range && range.min.toString() : null;
        const max = dataType === 'integer' && range ?  range && range.max.toString() : null;

        return ( 
            <FormControl fullWidth={true} disabled={isReadonly}>
                <InputLabel shrink={true} className="label-container" htmlFor={id}>
                    {localize.dynamicDescription(this.props)}
                    {
                        this.hasErrors() ? <SwyxInvalidTooltip errorMessage={errorMessage} /> : null
                    }
                </InputLabel>
                <Input
                    classes={{root: "input-text"}} 
                    inputProps={{
                        id,
                        required,
                        type: inputType,
                        maxLength,
                        min,
                        max,
                        value: this.state.value,
                        onChange: e => this.onChange(e.target.value, onValueChanged),
                        onBlur: () => this.validate()                    
                    }} 
                    disableUnderline={true} 
                    error={this.hasErrors()}
            />
            </FormControl>            
        )
    }
}

export default SwyxTextBox;