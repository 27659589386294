import React,  { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { colors } from '../themes/swyx-theme-properties';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    root: {
        color: colors.grey90,
        fontSize: "1.4rem",
        marginLeft: "1rem"
    }
});

class SwyxInvalidTooltip extends Component {
    render() {
        const { errorMessage, classes } = this.props;
        return (
            <span>
                <Tooltip title={errorMessage} 
                    placement="top-start"
                    arrow={true}
                >
                    <HelpOutlineIcon classes={{root: classes.root}}/>
                </Tooltip>
            </span>
        )        
    } 
}

export default withStyles(styles)(SwyxInvalidTooltip);