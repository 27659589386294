const RESOURCES = {
    mainPageHeader: {
        en: 'SwyxConnect Configuration Wizard',
        de: 'SwyxConnect Konfigurationsassistent'
    },

    resultHeader: {
        en: 'Result',
        de: 'Resultate'
    },

    templateSelectionHeader: {
        en: 'Template selection',
        de: 'Template auswählen'
    },

    templateSelectionCaption: {
        en: 'Template selection',
        de: 'Template auswählen'
    },

    templateSelectionButtonCaption: {
        en: 'Load template',
        de: 'Template laden'
    },

    backButtonCaption: {
        en: 'Back',
        de: 'Zurück'
    },

    nextButtonCaption: {
        en: 'Next',
        de: 'Weiter'
    },

    submitButtonCaption: {
        en: 'Submit',
        de: 'Absenden'
    },

    footerImprintCaption: {
        en: 'Imprint',
        de: 'Impressum'
    },

    footerImprintLink: {
        en: 'https://www.swyx.com/imprint.html',
        de: 'https://www.swyx.de/impressum.html'
    },

    footerDataPrivacyCaption: {
        en: 'Data Privacy',
        de: 'Datenschutz'
    },

    footerDataPrivacyLink: {
        en: 'https://www.swyx.com/swyxon-data-privacy.html',
        de: 'https://www.swyx.de/swyxon-datenschutz.html'
    },

    downloadButtonCaption: {
        en: 'Download',
        de: 'Herunterladen'
    },

    copyToClipboardButtonCaption: {
        en: 'Copy to clipboard',
        de: 'In Zwischenablage kopieren'
    },

    restartButtonCaption: {
        en: 'Restart',
        de: 'Fortsetzen'
    },

    restartWizardHeader: {
        en: 'Restart wizard',
        de: 'Assistent fortsetzen'
    },

    restartWizardMessage: {
        en: 'The wizard will be restarted and the entered data will be lost. Do you want to restart the wizard?',
        de: 'Der Assistent wird neu gestartet und alle eingegebenen Daten gehen verloren. Möchten Sie den Assistenten neu starten?'
    },

    yesButtonCaption: {
        en: 'Yes',
        de: 'Ja'
    },

    noButtonCaption: {
        en: 'No',
        de: 'Nein'
    },

    requiredMessage: {
        en: `The field '##' is required`,
        de: `Eingabe '##' is erforderlich`,
    },

    fqdnMessage: {
        en: `The field '##' must be a valid fully qualified domain name`,
        de: `de The field '##' must be a valid fully qualified domain name`
    },

    ipMessage: {
        en: `The field '##' must be a valid ip address`,
        de: `de The field '##' must be a valid ip address`
    },

    integerMessage: {
        en: `The field '##1' must be an integer value`,
        de: `de The field '##1' must be an integer value`,
    },

    integerRangeMessage: {
        en: `The field '##1' must be an integer value between ##2 and ##3`,
        de: `de The field '##1' must be an integer value between ##2 and ##3`,
    },

    maxLengthMessage: {
        en: `The field '##1' must not have more than ##2 characters`,
        de: `de The field '##1' must not have more than ##2 characters`,
    },

    genericErrorMessage: {
        en: 'Error in processing the server request',
        de: 'Fehler bei der Verarbeitung der Serveranfrage'
    },

    missingFileMessage: {
        en: 'Missing file',
        de: 'Fehlende Datei'
    },

    invalidParameterMessage: {
        en: 'Invalid parameter',
        de: 'Ungültiger Parameter'
    },

    missingKeyMessage: {
        en: 'JSON validation: Missing or incorrect key',
        de: 'JSON Validierung: Fehlender oder falscher Key'
    },

    duplicatePlaceholdersMessage: {
        en: 'JSON validation: Duplicate placeholders',
        de: 'JSON Validierung: Doppelt vorhandene Platzhalter'
    },

    placeholdersMismatchMessage: {
        en: 'JSON validation: Placeholders mismatch',
        de: 'JSON Validierung: Platzhalter passen nicht zusammen'
    },

    invalidDefaultValueMessage: {
        en: 'JSON validation: Invalid default value',
        de: 'JSON Validierung: Ungültiger Standardwert'
    },

    duplicateIdsMessage: {
        en: 'JSON validation: Duplicate IDs',
        de: 'JSON validation: Doppelt vorhandene IDs'
    },

    invalidOperatorMessage: {
        en: 'CLI validation: Invalid operator',
        de: 'CLI validation: Ungültiger Operator'
    },

    invalidIfConditionMessage: {
        en: 'CLI validation: Invalid If Condition',
        de: 'CLI validation: Ungültige If-Condition'
    },

    missingEndIfMessage: {
        en: 'CLI validation: Missing End If',
        de: 'CLI validation: Fehlendes End-If'
    },

    invalidNumberMessage: {
        en: 'CLI validation: Invalid number',
        de: 'CLI validation: Ungültige Zahl'
    },

    invalidPlaceholderMessage: {
        en: 'JSON validation: Invalid placeholder value',
        de: 'JSON validation: Ungültiger Platzhalterwert'
    },

    languages: {
        english: 'English',
        english2letters: '.en',
        german: 'Deutsch',
        german2letters: '.de'
    }

}

export default RESOURCES;