import React, { Component } from 'react';
import InputElement from '../inputs/input-element';
import { Grid, Button } from '@material-ui/core';
import Validation from '../../infrastructure/validation';

class DynamicPage extends Component {
    state = { isPageValid : false }

    componentDidMount() {
        this.validate();
    }

    componentDidUpdate() {
        this.validate();
    }

    goBack = () => {
        if(this.state.isPageValid) {
            this.props.goBack();
        }                
    }

    goNext = () => {
        if(this.state.isPageValid) {
            this.props.goNext();
        }
    }

    onFieldValueChanged = (id, value) => {
        this.props.onFieldValueChanged(id, value);
    }

    validate = () => {
        const validation = new Validation();
        const componentsAreValid = validation.componentsAreValid(this.props.viewModel);
        if (this.state.isPageValid !== componentsAreValid) {
            this.setState({ isPageValid: componentsAreValid });
        }
    }

    render() {
        const vm = this.props.viewModel;

        return (vm &&
            <div>
                <Grid container>
                    {vm.components.map((element) => {
                        const forceLineBreakBefore = element.forceLineBreakBefore;
                        return (
                            <>
                                {
                                    forceLineBreakBefore && <Grid item xs={12} sm={12}></Grid>
                                }
                                <Grid key={element.id} item xs={12} sm={6}>
                                    <InputElement viewModel={element} onValueChanged={this.onFieldValueChanged} localize={vm.localize} />
                                </Grid>
                            </>
                        );
                    })}                    
                </Grid>
                <Grid container>
                    <Grid item xs={12} >
                        <Button variant="outlined" onClick={this.goNext} className="float-right left-margin-32" >{this.props.goNextLabel}</Button>
                        <Button variant="outlined" onClick={this.goBack} className="float-right">{vm.localize.backButtonCaption()}</Button>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default DynamicPage;