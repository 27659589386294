import { createMuiTheme } from '@material-ui/core/styles';
import { fontFamilies, colors } from './swyx-theme-properties';

const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            root: {
                fontSize: "1.4rem",
                paddingLeft: "0.8rem",
                paddingRight: "0.8rem",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
                color: colors.grey90,
                borderRadius: "0",
                textTransform: "initial",
                marginBottom: "1.5rem",

                "&:hover": {
                    borderColor: colors.highlightBlue,
                    color: colors.highlightBlue,
                    backgroundColor: colors.white
                },

                "&.left-margin-32 ": {
                    marginLeft: "3.2rem"
                }
            },
        },

        MuiInputBase: {
            input: {
                fontFamily: fontFamilies.roboto,
                fontSize: "1.4rem",
                paddingLeft: "0.8rem",
                paddingRight: "1.6rem",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem"
            },

            inputMultiline: {
                lineHeight: "140%"
            }
        },

        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
                borderColor: colors.grey10
            }
        },

        MuiInput: {
            input: {
                backgroundColor: colors.grey04,
                borderColor: colors.grey10,
                color: colors.grey90,
                height: "2.4rem",
                "&$disabled": {
                    backgroundColor: colors.white,
                    color: colors.offlineGrey
                }
            }
        },

        MuiInputLabel: {
            root: {
                fontFamily: fontFamilies.robotoLight,
                color: colors.grey90,
                fontSize: "1.6rem",
                marginBottom: "0.8rem",
                "&$disabled": {
                    color: colors.grey90
                }
            },

            shrink: {
                transform: "translate(0, -0.8em) scale(1)"
            }
        },

        MuiSvgIcon: {
            root: {
                fontSize: "2.6rem",
                borderRadius: "0"
            }
        },

        MuiFormControlLabel: {
            label: {
                fontSize: "1.4rem"
            }
        },

        MuiTabs: {
            root: {
                borderBottom: "1px solid " + colors.grey10
            },

            indicator: {
                height: "3px",
                backgroundColor: colors.highlightBlue
            }
        },

        MuiTab: {
            root: {
                paddingRight: "1.2rem",
                paddingBottom: "1.2rem",
                fontFamily: fontFamilies.robotoLight,
                fontSize: "1.4rem",
                fontWeight: 400,
                color: colors.grey90,
                textTransform: "none"
            },

            textColorInherit: {
                opacity: 1,
                color: colors.grey90,

                "&$disabled": {
                    opacity: 1
                },

                "&$selected": {
                    fontFamily: fontFamilies.robotoMedium,
                    color: colors.highlightBlue
                }
            },
        },

        MuiGrid: {
            container: {
                marginTop: "1.5rem"
            },

            item: {
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem"
            }
        },

        MuiTooltip: {
            tooltip: {
                backgroundColor: colors.white,
                fontSize: "1.2rem",
                color: colors.red,
                opacity: "1",
                boxShadow: "0px 0px 1.4rem 0px rgba(0,0,0,0.21);"
            },
            arrow: {
                color: colors.white
            }
        },

        MuiPopover: {
            paper: {
                marginTop: '4.5rem',
                marginLeft: '-2rem'
            }
        },

        MuiTypography: {
            h6: {
                fontFamily: fontFamilies.robotoMedium,
                color: colors.highlightBlue,
                fontSize: "1.6rem"
            },
            body1: {
                fontFamily: fontFamilies.roboto,
                fontSize: "1.4rem"
            }
        },

        MuiDialogActions: {
            root: {
                padding: "0 3rem 0 0"
            }
        }
    }
})

export default theme;