import DependencyCalculator from './dependency-calculator'

class ModelParser {
    static initializeViewModel(model) {
        const viewModel = JSON.parse(JSON.stringify(model)); // deep copy so we don't work by reference on original model
        this.updateViewModel(viewModel);
        const routes = this.extractRoutes(viewModel);

        return { viewModel, routes };
    };

    static updateViewModel(viewModel) {
        let wizardStepNumber = 0;
        let componentsValues = DependencyCalculator.getComponentsValues(viewModel);

        for (let page of viewModel) {
            if (page.visibility) {
                page.isVisible = DependencyCalculator.isDependencySatisfied(page.visibility, componentsValues);
            } else {
                page.isVisible = true;
            }

            if (page.isVisible) {
                wizardStepNumber++;
                page.number = wizardStepNumber;
            }

            for (let component of page.components) {
                if (!page.isVisible) {
                    component.isVisible = false;
                    component.value = component.defaultValue;
                    componentsValues[component.id] = component.value;
                    continue;
                }

                if (component.visibility) {
                    component.isVisible = DependencyCalculator.isDependencySatisfied(component.visibility, componentsValues);
                } else {
                    component.isVisible = true;
                }

                if (component.isVisible && component.readonly) {
                    component.isReadonly = DependencyCalculator.isDependencySatisfied(component.readonly, componentsValues);
                } else {
                    component.isReadonly = false;
                }

                if (component.value === undefined || !component.isVisible || component.isReadonly) {
                    component.value = component.defaultValue;
                }

                componentsValues[component.id] = component.value;
            }
        }
    };

    static updateViewModelState(viewModel, id, value) {
        for (let page of viewModel) {
            for (let component of page.components) {
                if (component.id === id) {
                    component.value = value;
                    this.updateViewModel(viewModel);
                    const routes = this.extractRoutes(viewModel);

                    return { viewModel, routes };
                }
            }
        }
    };

    static extractRoutes(viewModel) {
        const generatedRoutes = [];

        for (let page of viewModel) {
            if (page.isVisible) {
                generatedRoutes.push(`/wizardStep/${page.number}`);
            }
        }

        const routes = ['/', ...generatedRoutes, '/result'];

        return routes;
    };

    static extractValue(component) {
        let value = component.value;
        if (component.dataType === 'integer') {
            value = +value;
            if (typeof value === 'number' && Number.isInteger(value)) {
                return value;
            }
        }
        
        return value != null ? value : component.defaultValue;
    }

    static extractPlaceholderValues(viewModel) {
        let result = {};
        for (let page of viewModel) {
            for (let component of page.components) {
                let value = this.extractValue(component);
                result[component.placeholder] = value;
            }
        }

        return result;
    };
}

export default ModelParser;