module.exports = {
    fontFamilies: {
        roboto: "roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif",
        robotoLight: "robotolight, 'Helvetica Neue', Helvetica, Arial, sans-serif",
        robotoMedium: "robotomedium, 'Helvetica Neue', Helvetica, Arial, sans-serif",
        robotoBold: "robotobold, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },

    colors: {
        grey90: "#1a1a1a",
        grey10: "#e6e6e6",
        grey04: "#f0f0f0",
        offlineGrey: "#b3b3b3",
        highlightBlue: "#0073a8",
        white: "#ffffff",
        red: "#e92431"
    }

};
