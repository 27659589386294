import RESOURCES from '../scripts/resources';

class Localization {

    constructor(language) {
        this.language = language;
    }

    mainPageHeader() {
        return this.translate(RESOURCES.mainPageHeader);
    }

    resultHeader() {
        return this.translate(RESOURCES.resultHeader);
    }

    templateSelectionHeader() {
        return this.translate(RESOURCES.templateSelectionHeader);
    }

    templateSelectionCaption() {
        return RESOURCES.templateSelectionCaption;
    }

    templateSelectionButtonCaption() {
        return this.translate(RESOURCES.templateSelectionButtonCaption);
    }

    backButtonCaption() {
        return this.translate(RESOURCES.backButtonCaption);
    }

    nextButtonCaption() {
        return this.translate(RESOURCES.nextButtonCaption);
    }

    submitButtonCaption() {
        return this.translate(RESOURCES.submitButtonCaption);
    }

    footerImprintCaption() {
        return this.translate(RESOURCES.footerImprintCaption);
    }

    footerImprintLink() {
        return this.translate(RESOURCES.footerImprintLink);
    }

    footerDataPrivacyCaption() {
        return this.translate(RESOURCES.footerDataPrivacyCaption);
    }

    footerDataPrivacyLink() {
        return this.translate(RESOURCES.footerDataPrivacyLink);
    }

    downloadButtonCaption() {
        return this.translate(RESOURCES.downloadButtonCaption);
    }

    copyToClipboardButtonCaption() {
        return this.translate(RESOURCES.copyToClipboardButtonCaption);
    }

    restartButtonCaption() {
        return this.translate(RESOURCES.restartButtonCaption);
    }

    restartWizardHeader() {
        return this.translate(RESOURCES.restartWizardHeader);
    }

    restartWizardMessage() {
        return this.translate(RESOURCES.restartWizardMessage);
    }

    yesButtonCaption() {
        return this.translate(RESOURCES.yesButtonCaption);
    }

    noButtonCaption() {
        return this.translate(RESOURCES.noButtonCaption);
    }

    genericErrorMessage() {
        return this.translate(RESOURCES.genericErrorMessage);
    }

    invalidParameterMessage() {
        return this.translate(RESOURCES.invalidParameterMessage);
    }

    missingFileMessage() {
        return this.translate(RESOURCES.missingFileMessage);
    }

    missingKeyMessage() {
        return this.translate(RESOURCES.missingKeyMessage);
    }

    duplicatePlaceholdersMessage() {
        return this.translate(RESOURCES.duplicatePlaceholdersMessage);
    }

    placeholdersMismatchMessage() {
        return this.translate(RESOURCES.placeholdersMismatchMessage);
    }

    invalidDefaultValueMessage() {
        return this.translate(RESOURCES.invalidDefaultValueMessage);
    }

    duplicateIdsMessage() {
        return this.translate(RESOURCES.duplicateIdsMessage);
    }

    invalidOperatorMessage() {
        return this.translate(RESOURCES.invalidOperatorMessage);
    }

    invalidIfConditionMessage() {
        return this.translate(RESOURCES.invalidIfConditionMessage);
    }

    missingEndIfMessage() {
        return this.translate(RESOURCES.missingEndIfMessage);
    }

    invalidNumberMessage() {
        return this.translate(RESOURCES.invalidNumberMessage);
    }

    invalidPlaceholderMessage() {
        return this.translate(RESOURCES.invalidPlaceholderMessage);
    }

    requiredMessage(data) {
        const description = this.dynamicDescription(data);
        return this.translate(RESOURCES.requiredMessage).replace('##', description);
    }

    fqdnMessage(data) {
        const description = this.dynamicDescription(data);
        return this.translate(RESOURCES.fqdnMessage).replace('##', description);
    }

    ipMessage(data) {
        const description = this.dynamicDescription(data);
        return this.translate(RESOURCES.ipMessage).replace('##', description);
    }

    integerMessage(data) {
        const description = this.dynamicDescription(data);
        if (data.range){
            return this.translate(RESOURCES.integerRangeMessage).replace('##1', description).replace('##2', data.range.min).replace('##3', data.range.max);
        }
        return this.translate(RESOURCES.integerMessage).replace('##1', description);
    }

    maxLengthMessage(data) {
        const description = this.dynamicDescription(data);
        return this.translate(RESOURCES.maxLengthMessage).replace('##1', description).replace('##2', data.max);
    }

    dynamicDescription(data) {
        const description = this.getDescriptions(data);
        return this.translate(description);
    }

    dynamicOption(data) {
        const description = this.getDescriptions(data);
        return this.translate(description);
    }

    getDescriptions(data) {
        if (data.descriptionEN) {
            return { en: data.descriptionEN, de: data.descriptionDE };
        }

        if (data.labelEN) {
            return { en: data.labelEN, de: data.labelDE };
        }

        if (data.en) {
            return data;
        }

        throw new Error('Resource translation error');
    }

    translate(data) {
        const description = this.getDescriptions(data);
        switch (this.language) {
            case 'en':
                return description.en;
            case 'de':
                return description.de;
            default:
                throw new Error('Language not suppported');
        }
    }
}

export default Localization;