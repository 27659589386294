import ErrorHandler from './error-handling';

class StandaloneTemplatesApi {
    constructor(ipcApi, localize) {
        this.ipcApi = ipcApi;
        this.localize = localize;
    }

    handleError = (error, reject) => {
        const errorHandler = new ErrorHandler(this.localize);
        const handledError = errorHandler.handleError(error.code);
        reject(handledError);
    }

    getTemplates = () => {
        return new Promise((resolve, reject) => {
            this.ipcApi.send("get-available-templates");
            this.ipcApi.receive("available-templates-loaded", result => resolve(result));
            this.ipcApi.receive("get-available-templates-error", error => this.handleError(error, reject));
        });
    }

    getTemplate = templateName => {
        return new Promise((resolve, reject) => {
            this.ipcApi.send("load-template", templateName);
            this.ipcApi.receive("template-loaded", result => resolve(result));
            this.ipcApi.receive("load-template-error", error => this.handleError(error, reject));
        });
    }

    generateCli = (templateName, values) => {
        return new Promise((resolve, reject) => {
            this.ipcApi.send("generate-cli", {templateName, values});
            this.ipcApi.receive("cli-generated", result => resolve(result));
            this.ipcApi.receive("generate-cli-error", error => this.handleError(error, reject));
        });
    }
}

export default StandaloneTemplatesApi;