import React, { Component } from 'react';
import { Checkbox, FormControlLabel, FormControl } from '@material-ui/core';

class SwyxCheckBox extends Component {
    constructor(props) {
        super(props);
        const { value, defaultValue } = this.props;
        this.state = { value: value !== undefined ? value : defaultValue };
    };

    onChange = (e, onValueChanged, isReadonly) => {
        // validate
        if (isReadonly) {
            e.preventDefault();
            return;
        }
        const newValue = e.target.checked;
        this.setState({ value: newValue });
        onValueChanged(newValue);
    };

    render() {
        const {
            isReadonly,
            onValueChanged,
            id,
            localize } = this.props;

        return ( 
            <FormControl disabled={isReadonly}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id={id.toString()}
                            checked={this.state.value}                            
                            onChange={ e => this.onChange(e, onValueChanged, isReadonly)}
                            color='default' />
                    }
                    label={localize.dynamicDescription(this.props)}
                />
            </FormControl>
        )
    }
}

export default SwyxCheckBox;