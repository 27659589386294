import React, { Component } from 'react';
import DynamicWizard from './components/dynamic-wizard'
import './app.css';
import SwyxHeader from './components/swyx-header';
import { BrowserRouter as Router } from 'react-router-dom';
import Localization from './scripts/localization';
import { ThemeProvider } from '@material-ui/core/styles';
import swyxTheme from './themes/swyx-theme';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { language: 'en' };
  }

  languageChanged = language => {
    this.setState({ language });
  }

  render() {
    const localize = new Localization(this.state.language);
    const linkStyle = {
      "paddingLeft": "3px",
      "paddingRight": "3px",
      "textDecoration": "none",
      "color": "#bbb",
    };
    return (
      <ThemeProvider theme={swyxTheme} >
        <div>
          <div style={{ "minHeight": 'calc(100vh - 20px)' }}>
            <SwyxHeader languageChanged={this.languageChanged} />
            <div className="content">
              <h1>{localize.mainPageHeader()}</h1>
              <Router>
                <DynamicWizard localize={localize} />
              </Router>
            </div>
          </div>
          <div style={{ "height": '20px', "textAlign": "right", "bottom": "5px" }}>
            <a href={localize.footerDataPrivacyLink()} target="blank" style={linkStyle}>{localize.footerDataPrivacyCaption()}</a>
            &nbsp;|&nbsp;
            <a href={localize.footerImprintLink()} target="blank" style={linkStyle}>{localize.footerImprintCaption()}</a>
          </div>
        </div>
      </ThemeProvider >
    );
  }
}

export default App;